import { fetchDeliveries } from '@/helpers/axios/configs/deliveriesAxios.ts';
import { fetchInstitutionGroupTags } from '@/helpers/axios/configs/institutionGroupTagsAxios.ts';
import { fetchServiceRequests } from '@/helpers/axios/configs/serviceRequestsAxios.ts';
import { EFilterType } from '@/types/enum/filterType.ts';
import { AxiosError } from 'axios';
import { TGenericObject } from '../../types/commonTypes.ts';
import { AuthProvider } from '../authProvider.ts';
import { mergeFilterString } from '../filters.ts';
import { fetchAccount } from './configs/accountAxios.ts';
import { fetchAccounts } from './configs/accountsAxios.ts';
import { fetchAppInstitutions } from './configs/appInstitutionsAxios.ts';
import { fetchContent } from './configs/contentAxios.ts';
import { fetchContract } from './configs/contractsAxios.ts';
import { fetchCredentials } from './configs/credentialsAxios.ts';
import { fetchDevice } from './configs/deviceAxios.ts';
import { fetchDeviceLoan } from './configs/deviceLoanAxios.ts';
import { fetchFieldService } from './configs/fieldServiceAxios.ts';
import { fetchImpersonation } from './configs/impersonationAxios.ts';
import { fetchInstitutionAddresses } from './configs/institutionAddressesAxios.ts';
import { fetchInstitutionContacts } from './configs/institutionContactsAxios.ts';
import { fetchInstitutionOrders } from './configs/institutionOrdersAxios';
import { fetchInstitutionsRoles } from './configs/institutionRolesAxios.ts';
import { fetchInstitutions } from './configs/institutionsAxios.ts';
import { fetchOrders } from './configs/ordersAxios.ts';
import { fetchOsImage } from './configs/osImageAxios.ts';
import { fetchUser } from './configs/userAxios.ts';
import { fetchUserType } from './configs/userTypesAxios.ts';
import { fetchwebshopConfigurations } from './configs/webshopConfigurations.ts';
import { fetchWebshops } from './configs/webshopsAxios.ts';

type TFilters = {
  [key: string]: string[];
};

const axiosServices = [
  fetchContent,
  fetchFieldService,
  fetchDevice,
  fetchUser,
  fetchAccount,
  fetchContract,
  fetchInstitutions,
  fetchOsImage,
  fetchCredentials,
  fetchInstitutionContacts,
  fetchInstitutionAddresses,
  fetchImpersonation,
  fetchDeviceLoan,
  fetchInstitutionsRoles,
  fetchUserType,
  fetchOrders,
  fetchAppInstitutions,
  fetchAccounts,
  fetchwebshopConfigurations,
  fetchWebshops,
  fetchInstitutionOrders,
  fetchServiceRequests,
  fetchInstitutionGroupTags,
  fetchDeliveries,
];

export const initAxiosServices = (
  filters?: TFilters,
  queryParams: TGenericObject = {},
) => {
  axiosServices.forEach((service) => {
    service.defaults.headers.common.Authorization = `Bearer ${AuthProvider.authToken}`;
    service.interceptors.response.use(
      (res) => res,
      async (error: AxiosError) => {
        if (error.status === 401) {
          const redirectUrl = await AuthProvider.checkAuthentication();
          if (redirectUrl) window.location.href = redirectUrl;
        }
        return await Promise.reject(error);
      },
    );

    service.interceptors.request.use(async (config) => {
      const redirectUrl = await AuthProvider.checkAuthentication();
      if (redirectUrl) window.location.href = redirectUrl;
      return config;
    });

    if (filters) {
      const filterString = Object.keys(filters)
        .map((filter) => {
          const temp = filters[filter].join('').split('');
          return filters[filter] && temp.length > 0
            ? `${filter} in ${filters[filter].join(',')}`
            : null;
        })
        .filter((value) => value)
        .join('and');
      service.interceptors.request.use((config) => {
        if (
          config.method === 'get' &&
          config.addCurrentInstitution &&
          !config.params?.$filter?.includes(`institutionGuid ${EFilterType.In}`)
        ) {
          const $filter = mergeFilterString(
            filterString,
            config.params?.$filter,
          );
          return {
            ...config,
            params: {
              ...config.params,
              ...queryParams,
              $filter,
            },
          };
        }
        return config;
      });
    }
  });
};
