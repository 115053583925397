import { createSlice } from '@reduxjs/toolkit';

export type THideInstitution = boolean;

const initialState: THideInstitution = false;

const hideInstitutionSlice = createSlice({
  name: 'hidden',
  initialState,
  reducers: {
    setInstitutionHidden: (_state, action) => action.payload,
  },
});

export const { setInstitutionHidden } = hideInstitutionSlice.actions;

export default hideInstitutionSlice.reducer;
