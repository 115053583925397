import { ReactNode } from 'react';
import { useEdgeDetection } from '../../hooks/useEdgeDetection';

type TTooltipProps = {
  message: string;
  children: ReactNode;
};

const Tooltip = ({ message, children }: TTooltipProps) => {
  // Use edge detection hook to track tooltip position
  const [tooltipRef, _showAbove, _showLeft] =
    useEdgeDetection<HTMLDialogElement>();

  return (
    <div className='relative group'>
      {children}
      <dialog
        ref={tooltipRef}
        className={`absolute bg-app-400 rounded-md px-4 py-2 block pointer-events-none opacity-0 group-hover:opacity-100 z-20 transition-all text-sm text-app-500 min-w-48 
                    ${_showAbove ? '-top-1 -translate-y-full' : ''} 
                    ${_showLeft ? 'left-full -translate-x-full' : ''}`}>
        {message}
      </dialog>
    </div>
  );
};

export default Tooltip;
