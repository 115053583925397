import { createSlice } from '@reduxjs/toolkit';
import {
  TBulkIncidentForm,
  TCreateBulkIncident,
} from '@/types/createIncidentTypes';
import { addWeekdays } from '@/helpers/dateHelpers';

const bulkFormDefaultValues: TBulkIncidentForm = {
  defects: [],
  practical: {
    pickup: [
      addWeekdays(new Date(), 3).toISOString(),
      addWeekdays(new Date(), 7).toISOString(),
    ],
    delivery: [
      addWeekdays(new Date(), 30).toISOString(),
      addWeekdays(new Date(), 34).toISOString(),
    ],
    insuranceCheck: false,
  },
  repairLocation: {
    isUser: false,
    institution: {
      guid: '',
      address: {
        street: '',
        number: '',
        box: '',
        zipCode: '',
        city: '',
        countryCode: '',
      },
      contacts: [
        {
          firstName: '',
          lastName: '',
          email: '',
        },
      ],
    },
    user: {
      address: {
        street: '',
        number: '',
        box: '',
        zipCode: '',
        city: '',
        countryCode: '',
      },
      contacts: [
        {
          firstName: '',
          lastName: '',
          email: '',
        },
      ],
    },
  },
  invoice: {
    isUser: false,
    institution: {
      guid: '',
      address: {
        street: '',
        number: '',
        box: '',
        zipCode: '',
        city: '',
        countryCode: '',
      },
      contact: {
        firstName: '',
        lastName: '',
        email: '',
      },
    },
    user: {
      address: {
        street: '',
        number: '',
        box: '',
        zipCode: '',
        city: '',
        countryCode: '',
      },
      contact: {
        firstName: '',
        lastName: '',
        email: '',
      },
    },
    insuranceCheck: false,
    flatRateCost: false,
  },
};

export const initialCreateBulkIncident: TCreateBulkIncident = {
  step: {
    current: 1,
    amount: 1,
  },
  guid: '',
  form: bulkFormDefaultValues,
};

const createBulkIncidentSlice = createSlice({
  name: 'createBulkIncident',
  initialState: initialCreateBulkIncident,
  reducers: {
    setStepAmount: (state, action) => {
      state.step.amount = action.payload;
    },
    resetStep: (state) => {
      state.step.current = 1;
      if (state.step.topStep) state.step.topStep = 1;
    },
    nextStep: (state) => {
      state.step.current = Math.min(state.step.current + 1, state.step.amount);
      if (state.step.topStep)
        state.step.topStep = Math.min(
          state.step.topStep + 1,
          state.step.amount,
        );
    },
    previousStep: (state) => {
      state.step.current = Math.max(state.step.current - 1, 1);
    },
    setStep: (state, { payload }) => {
      let newStep = payload;
      if (payload <= 1) newStep = 1;
      if (payload >= (state.step.topStep || state.step.amount))
        newStep = state.step.topStep || state.step.amount;
      state.step.current = newStep;
    },
    updateFormState: (state, action) => {
      state.form = { ...state.form, ...action.payload };
    },
    setIncidentGuid: (state, action) => {
      state.guid = action.payload;
    },
    reset: () => initialCreateBulkIncident,
  },
});

export const {
  nextStep,
  previousStep,
  setStep,
  setStepAmount,
  updateFormState,
  setIncidentGuid,
  resetStep,
  reset,
} = createBulkIncidentSlice.actions;

export default createBulkIncidentSlice.reducer;
