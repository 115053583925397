type TBoundaryElement = {
  height: number;
  width: number;
};

export type TEdgeReturn = {
  x: 'left' | 'right' | 'center';
  y: 'top' | 'bottom' | 'center';
};

type TDetectEdge = (
  rectangle: HTMLElement,
  boundaryElement: TBoundaryElement,
  tolerance?: number,
) => TEdgeReturn;

// Array to store transformation values
let transformArr: number[] = [0, 0, 0, 0, 0, 0];

// Function to nullify transformations of an element
function nullifyTransforms(el: HTMLElement) {
  // Function to parse transform CSS property
  const parseTransform = (el: HTMLElement) =>
    window
      .getComputedStyle(el)
      .transform.split(/\(|,|\)/)
      .slice(1, -1)
      .map((v) => parseFloat(v));

  // Functions to get computed style properties
  const computedTop = (el: HTMLElement) =>
    parseFloat(window.getComputedStyle(el).top) || 0;
  const computedLeft = (el: HTMLElement) =>
    parseFloat(window.getComputedStyle(el).left) || 0;
  const computedBottom = (el: HTMLElement) =>
    parseFloat(window.getComputedStyle(el).bottom) || 0;
  const computedRight = (el: HTMLElement) =>
    parseFloat(window.getComputedStyle(el).right) || 0;

  // Get bounding rectangle of the element
  const { top, left, width, height } = el.getBoundingClientRect();

  // Parse transform array
  const newTransformArr = parseTransform(el);
  if (newTransformArr.length === 6) transformArr = newTransformArr;

  // Calculate new positions after nullifying transformations
  const newTop = top - transformArr[5] - computedTop(el);
  const newLeft = left - transformArr[4] - computedLeft(el);
  const newBottom =
    newTop + height - transformArr[5] - computedTop(el) - computedBottom(el);
  const newRight =
    newLeft + width - transformArr[4] - computedLeft(el) - computedRight(el);

  // Return nullified transforms and dimensions
  return {
    top: newTop,
    left: newLeft,
    bottom: newBottom,
    right: newRight,
    width,
    height,
  };
}

// Main function for edge detection
export const detectEdge: TDetectEdge = (
  element,
  boundaryElement,
  tolerance = 10,
) => {
  // Initialize edge return object
  const ret: TEdgeReturn = {
    x: 'center',
    y: 'center',
  };

  // Get nullified rectangle position and dimensions
  const rectangle = nullifyTransforms(element);

  // Y axis detection
  if (
    rectangle.bottom < boundaryElement.height - tolerance &&
    rectangle.top - tolerance > tolerance
  ) {
    ret.y = 'center';
  } else if (rectangle.top > tolerance) {
    ret.y = 'top';
  } else if (rectangle.top < boundaryElement.height - tolerance) {
    ret.y = 'bottom';
  }

  // X axis detection
  if (
    rectangle.right < boundaryElement.width - tolerance &&
    rectangle.left > tolerance
  ) {
    ret.x = 'center';
  } else if (rectangle.left > tolerance) {
    ret.x = 'left';
  } else if (rectangle.right < boundaryElement.width - tolerance) {
    ret.x = 'right';
  }

  // Return detected edges
  return ret;
};
