import logo from '@/assets/logoDark.svg';

const Loader = () => {
  return (
    <div className='w-full grid place-items-center py-8 animate-pulse'>
      <img src={logo} className='opacity-30' />
    </div>
  );
};

export default Loader;
