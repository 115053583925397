import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const FailedToSignIn = () => {
  return (
    <section className='flex justify-center items-center mt-8'>
      <div className='max-w-2xl w-full flex flex-col gap-4 items-start bg-app-200 p-4 rounded-xl'>
        <div>
          <h1 className='font-medium text-lg mb-2'>Failed to signin</h1>
          <p>Click below to try again.</p>
        </div>
        <Link
          reloadDocument
          className='flex items-center gap-2 text-app-100 bg-app-600 hover:bg-app-700 transition-colors px-4 py-2 rounded-md'
          to='/'>
          <span>Go to signin</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
        <p className='text-app-500 text-sm mt-4'>
          If this keeps happening contact us at{' '}
          <a
            href='mailto:ICTSupport@signpost.eu'
            target='_blank'
            className='underline hover:text-app-600 transition-colors'>
            ICTSupport@signpost.eu
          </a>
          .
        </p>
      </div>
    </section>
  );
};

export default FailedToSignIn;
