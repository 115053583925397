import { createSlice } from '@reduxjs/toolkit';

export type TSelectedInstitution = {
  guid: string;
  name: string;
} | null;

const initialState: TSelectedInstitution = null;

const institutionSlice = createSlice({
  name: 'institution',
  initialState: initialState as TSelectedInstitution,
  reducers: {
    setInstitution: (_state, action: { payload: TSelectedInstitution }) =>
      action.payload,
  },
});

export const { setInstitution } = institutionSlice.actions;

export default institutionSlice.reducer;
