import { QueryClientProvider } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { ReactNode, useEffect } from 'react';
import { queryClient } from './helpers/queryClient';

const QueryWrapper = ({ children }: { children: ReactNode }) => {
  const currentInstitution = useSelector(
    (state: RootState) => state.currentInstitution,
  );

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['refetchOnInstitutionChange'] });
  }, [currentInstitution]);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryWrapper;
