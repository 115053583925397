import Loader from '@/components/common/loader';
import Header from '@/components/header/header';
import SideBar from '@/components/menu/sidebar';
import SearchModal from '@/components/search/searchModal';
import { initAxiosServices } from '@/helpers/axios/axiosLoader';
import { fetchContent } from '@/helpers/axios/configs/contentAxios';
import { routingAdded } from '@/redux/slices/routingSlice';
import { RootState } from '@/redux/store';
import { PaginatedCollection } from '@/types/apiResponse';
import { ErrorBoundary } from '@sentry/react';
import { GetNavigationSideMenuResponse } from '@signpost-hardware/management-portal-models/v1/app/content/navigation/side-menu/responses/get/get_navigation_side_menu_response_pb';
import { useQuery } from '@tanstack/react-query';
import { Suspense, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useRegisterSW } from 'virtual:pwa-register/react';

const UpdateApp = () => {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      const intervalMS = 45 * 60 * 1000;
      if (r)
        setInterval(() => {
          r.update();
        }, intervalMS);
    },
    onNeedRefresh() {
      setNeedRefresh(true);
    },
  });

  if (!needRefresh) return null;

  return (
    <div className='fixed bottom-0 right-0 bg-app-100 p-4 m-4 shadow-lg shadow-app-800/10 rounded-xl updateToast'>
      <h2 className='text-lg font-medium mb-2'>
        A new app update is available.
      </h2>
      <p className='text-sm'>
        We recommend updating the app as some parts may not work correctly. Be
        aware that you may lose form progress.
      </p>
      <div className='flex items-center gap-4 justify-end mt-4'>
        <button
          type='button'
          className='text-sm text-app-500 hover:text-app-600 transition-colors'
          onClick={() => setNeedRefresh(false)}>
          Close
        </button>
        <button
          className='text-app-100 bg-app-700 px-4 py-2 rounded-md flex items-center gap-4 hover:bg-app-600 transition-colors'
          type='button'
          onClick={() => {
            updateServiceWorker(true);
          }}>
          Update
        </button>
      </div>
    </div>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state: RootState) => state.language.lcid);
  const currentInstitution = useSelector(
    (state: RootState) => state.currentInstitution,
  );

  useMemo(() => {
    initAxiosServices(
      { institutionGuid: [currentInstitution?.guid || ''] },
      { locale: lang },
    );
  }, [currentInstitution, lang]);

  const { isLoading, error } = useQuery({
    queryKey: ['routing', lang],
    queryFn: async () => {
      const { data } = await fetchContent.get<
        PaginatedCollection<GetNavigationSideMenuResponse>
      >(`/navigation/sitemap?locale=${lang}`);
      dispatch(routingAdded(data.items));
      return data.items;
    },
  });

  if (isLoading) return <></>;
  // TODO: Create app error
  if (error) return <div>failed to load app</div>;

  return (
    <>
      <div className='app-layout'>
        <SideBar />
        <main className='px-4 pb-8 print:p-0 relative' id='page'>
          <Header />
          <Suspense fallback={<Loader />}>
            <ErrorBoundary fallback={<div>Failed to load page</div>}>
              <Outlet />
            </ErrorBoundary>
            <SearchModal />
          </Suspense>
        </main>
        <UpdateApp />
        <ToastContainer
          position='bottom-center'
          stacked
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </div>
    </>
  );
};

export default App;
