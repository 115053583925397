import { fetchInstitutions } from '@/helpers/axios/configs/institutionsAxios';
import { setInstitution } from '@/redux/slices/institutionSlice';
import { setPermissions } from '@/redux/slices/permissionSlice';
import { RootState } from '@/redux/store';
import { PaginatedCollection } from '@/types/apiResponse';
import { InstitutionsByAccountResponse } from '@signpost-hardware/management-portal-models/v1/institutions/responses/institutions_by_account_response_pb';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

const InstitutionName = () => {
  const dispatch = useDispatch();
  const currentInstitution = useSelector(
    (state: RootState) => state.currentInstitution,
  );
  const currentPermissions = useSelector(
    (state: RootState) => state.permissions,
  );
  const location = useLocation();
  const { data, isLoading, error } = useQuery<InstitutionsByAccountResponse[]>({
    queryKey: ['institutions', currentInstitution, currentPermissions.length],
    queryFn: async () => {
      const res =
        await fetchInstitutions.get<
          PaginatedCollection<InstitutionsByAccountResponse>
        >('');
      if (
        !currentInstitution ||
        res.data.items.findIndex(
          ({ guid }) => guid === currentInstitution.guid,
        ) === -1
      ) {
        dispatch(
          setInstitution({
            guid: res.data.items[0].guid as string,
            name: res.data.items[0].alias || res.data.items[0].name,
          }),
        );
        dispatch(setPermissions(res.data.items[0].permissions));
      }
      if (currentPermissions.length === 0 && currentInstitution) {
        dispatch(
          setPermissions(
            res.data.items.filter(
              (institution) => institution.guid === currentInstitution.guid,
            )[0].permissions,
          ),
        );
      }
      return res.data.items;
    },
  });

  if (error) return <div>error</div>;

  const selectorData = data?.map((item) => ({
    value: item.guid as string,
    label: item.alias || item.name,
  }));

  const hasMultiple =
    data && data.length > 1 && !location.pathname.includes('/institutions');

  return (
    <h1 className='md:inline text-lg font-medium px-4 w-full relative z-20'>
      <div className='grid grid-cols-1'>
        <span
          className={`${hasMultiple && !isLoading ? 'hidden' : ''} px-2 m-1 font-medium overflow-hidden text-ellipsis whitespace-nowrap`}>
          {data?.filter(
            (institution) => institution.guid === currentInstitution?.guid,
          )[0].name ||
            (data && data[0].name) ||
            currentInstitution?.name}
        </span>
      </div>
      {hasMultiple && (
        <Select
          aria-label='Select institution'
          classNamePrefix='institutionSelector'
          styles={{
            menu: (base) => ({
              ...base,
              width: 'max-content',
              minWidth: '100%',
            }),
          }}
          options={selectorData}
          onChange={(value) => {
            if (value) {
              dispatch(
                setInstitution({ guid: value.value, name: value.label }),
              );
              dispatch(
                setPermissions(
                  data.filter(
                    (institution) => institution.guid === value?.value,
                  )[0].permissions,
                ),
              );
            }
          }}
          isSearchable
          defaultValue={
            selectorData?.filter(
              (item) => item.value === currentInstitution?.guid,
            )[0] ||
            (selectorData && selectorData[0])
          }
        />
      )}
    </h1>
  );
};

export default InstitutionName;
