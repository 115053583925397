import { createRoutes } from './createRoutes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import * as Sentry from '@sentry/react';

export const Routing = () => {
  const routing = useSelector((state: RootState) => state.routing);
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter(createRoutes(routing), {
    future: {
      v7_normalizeFormMethod: true,
    },
  });

  return <RouterProvider router={router} />;
};
