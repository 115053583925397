import {
  LegacyRef,
  RefObject,
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useWindowResize } from './useWindowResize';
import { TEdgeReturn, detectEdge } from '../helpers/edgeDetection';

type TEdgeDetectOptions = {
  tolerance?: number;
};

type TRef<T> = RefObject<T> | LegacyRef<T>;

type TUseEdgeDetectionReturn<T> = [
  TRef<T>,
  boolean,
  boolean,
  TEdgeReturn | undefined,
];

export const useEdgeDetection = <T extends HTMLElement>(
  props?: TEdgeDetectOptions,
): TUseEdgeDetectionReturn<T> => {
  // Ref to the element being tracked
  const elementRef = useRef<T>(null);

  // State for storing element's position
  const [elementPosition, setElementPosition] = useState<TEdgeReturn>();

  // Deferred position state for smoother UI updates
  const deferredPosition = useDeferredValue(elementPosition);

  // State to track whether the element should be shown above or below the parent
  const [showAbove, setShowAbove] = useState(false);

  // State to track whether the element should be shown to the left or right of the parent
  const [showToLeft, setShowToLeft] = useState(false);

  // Callback to handle edge detection
  const handleEdgeDetect = useCallback(() => {
    const element = elementRef.current;
    const { innerHeight: height, innerWidth: width } = window;

    // Perform edge detection if element is available
    if (element)
      setElementPosition(
        detectEdge(element, { height, width }, props?.tolerance),
      );
  }, [props?.tolerance]);

  // Effect to trigger edge detection on initial mount and when handleEdgeDetect changes
  useEffect(() => {
    handleEdgeDetect();
  }, [handleEdgeDetect]);

  // Effect to trigger edge detection on window resize
  useWindowResize(handleEdgeDetect);

  // Effect to update showAbove and showToLeft states based on deferred position
  useEffect(() => {
    if (deferredPosition?.x === 'left') setShowToLeft(true);
    else setShowToLeft(false);
    if (deferredPosition?.y === 'top') setShowAbove(true);
    else setShowAbove(false);
  }, [deferredPosition?.x, deferredPosition?.y]);

  // Return references and states
  return [elementRef, showAbove, showToLeft, deferredPosition];
};
