import { Middleware, configureStore } from '@reduxjs/toolkit';
import routingSlice, { TRouting } from './slices/routingSlice';
import menuSlice, { TMenu } from './slices/menuSlice';
import languageSlice, { TLanguage } from './slices/languageSlice';
import createIncidentSlice from './slices/createIncidentSlice';
import institutionSlice, {
  TSelectedInstitution,
} from './slices/institutionSlice';
import searchSlice, { TSearchState } from './slices/searchSlice.ts';
import permissionSlice, { TPermission } from './slices/permissionSlice.ts';
import hideInstitutionSlice, {
  THideInstitution,
} from './slices/hideInstitutionSlice.ts';
import {
  TCreateBulkIncident,
  TCreateIncident,
} from '@/types/createIncidentTypes.ts';
import createBulkIncidentSlice from '@/redux/slices/createBulkIncidentSlice.ts';

export type RootState = {
  routing: TRouting[];
  menu: TMenu;
  language: TLanguage;
  createIncident: TCreateIncident;
  createBulkIncident: TCreateBulkIncident;
  currentInstitution: TSelectedInstitution;
  search: TSearchState;
  permissions: TPermission[];
  hideInstitution: THideInstitution;
};

type TSessionStorageProps = {
  getState: () => RootState;
};

const sessionStorageMiddleware: Middleware = ({
  getState,
}: TSessionStorageProps) => {
  return (next) => (action) => {
    const result = next(action);
    const { currentInstitution, permissions } = getState();
    sessionStorage.setItem(
      'applicationState',
      JSON.stringify({ currentInstitution, permissions }),
    );
    return result;
  };
};

const reHydrateStore = (): RootState | undefined => {
  if (sessionStorage.getItem('applicationState') !== null) {
    return JSON.parse(sessionStorage.getItem('applicationState') || '');
  }
};

export const store = configureStore({
  reducer: {
    routing: routingSlice,
    menu: menuSlice,
    language: languageSlice,
    createIncident: createIncidentSlice,
    createBulkIncident: createBulkIncidentSlice,
    currentInstitution: institutionSlice,
    search: searchSlice,
    permissions: permissionSlice,
    hideInstitution: hideInstitutionSlice,
  },
  preloadedState: reHydrateStore(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sessionStorageMiddleware),
});
