export const setCookie = (
  cname: string,
  cvalue: string,
  exSeconds: number,
  secure: boolean = false,
  attr: string = '',
) => {
  const d = new Date();
  d.setTime(d.getTime() + exSeconds * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/;${
    secure ? 'secure;' : ''
  }${attr}`;
};

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
