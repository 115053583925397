import { createSlice } from '@reduxjs/toolkit';
import { TCreateIncident, TIncidentForm } from '@/types/createIncidentTypes';

const formDefaultValues: TIncidentForm = {
  defect: {
    damageType: '',
    damageTypeGuid: '',
    usable: false,
    description: '',
    item: 'toestel',
    fallDamage: false,
    moistureDamage: false,
  },
  repairLocation: {
    isUser: false,
    institution: {
      guid: '',
      address: {
        street: '',
        number: '',
        box: '',
        zipCode: '',
        city: '',
        countryCode: '',
      },
      contacts: [
        {
          firstName: '',
          lastName: '',
          email: '',
        },
      ],
    },
    user: {
      address: {
        street: '',
        number: '',
        box: '',
        zipCode: '',
        city: '',
        countryCode: '',
      },
      contacts: [
        {
          firstName: '',
          lastName: '',
          email: '',
        },
      ],
    },
  },
  invoice: {
    isUser: false,
    institution: {
      guid: '',
      address: {
        street: '',
        number: '',
        box: '',
        zipCode: '',
        city: '',
        countryCode: '',
      },
      contact: {
        firstName: '',
        lastName: '',
        email: '',
      },
    },
    user: {
      address: {
        street: '',
        number: '',
        box: '',
        zipCode: '',
        city: '',
        countryCode: '',
      },
      contact: {
        firstName: '',
        lastName: '',
        email: '',
      },
    },
    insuranceCheck: false,
    flatRateCost: false,
  },
};

export const initialCreateIncident: TCreateIncident = {
  step: {
    current: 1,
    amount: 1,
  },
  guid: '',
  device: {},
  form: formDefaultValues,
};

const createIncidentSlice = createSlice({
  name: 'createIncident',
  initialState: initialCreateIncident,
  reducers: {
    setStepAmount: (state, action) => {
      state.step.amount = action.payload;
    },
    resetStep: (state) => {
      state.step.current = 1;
      if (state.step.topStep) state.step.topStep = 1;
    },
    nextStep: (state) => {
      state.step.current = Math.min(state.step.current + 1, state.step.amount);
      if (state.step.topStep)
        state.step.topStep = Math.min(
          state.step.topStep + 1,
          state.step.amount,
        );
    },
    previousStep: (state) => {
      state.step.current = Math.max(state.step.current - 1, 1);
    },
    setStep: (state, { payload }) => {
      let newStep = payload;
      if (payload <= 1) newStep = 1;
      if (payload >= (state.step.topStep || state.step.amount))
        newStep = state.step.topStep || state.step.amount;
      state.step.current = newStep;
    },
    updateFormState: (state, action) => {
      state.form = { ...state.form, ...action.payload };
    },
    setIncidentGuid: (state, action) => {
      state.guid = action.payload;
    },
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    reset: () => initialCreateIncident,
  },
});

export const {
  nextStep,
  previousStep,
  setStep,
  setStepAmount,
  updateFormState,
  setIncidentGuid,
  resetStep,
  setDevice,
  reset,
} = createIncidentSlice.actions;

export default createIncidentSlice.reducer;
