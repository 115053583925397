import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { RenderIcon } from '../../helpers/renderProps/renderIcon';
import { TRouting } from '../../redux/slices/routingSlice';

const NavItem = ({
  route,
  child = false,
  onClick,
}: {
  route: TRouting;
  child?: boolean;
  onClick?: () => void;
}) => {
  return (
    <NavLink
      to={route.path}
      end={child}
      onClick={onClick}
      className={({ isActive }) =>
        [
          isActive
            ? 'group isActive bg-app-600 relative before:content-[""] before:absolute before:left-0 before:top-0 before:w-1 before:h-full before:bg-signpost'
            : '',
          `nav-item ${child ? 'pl-5' : ''}`,
        ].join(' ')
      }>
      <RenderIcon
        render={(Icon) => (
          <FontAwesomeIcon
            icon={Icon}
            className={`${child ? 'h-5 ' : 'h-6 '}w-full object-contain group-[.isActive]:text-signpost`}
          />
        )}
        guid={route.guid}
      />
      <span
        className={`${child ? 'text-sm ' : ''}whitespace-nowrap overflow-hidden text-ellipsis w-full col-start-2`}>
        {route.displayName}
      </span>
    </NavLink>
  );
};

export default NavItem;
