import {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLProps,
  ReactNode,
  RefAttributes,
} from 'react';
import { ECardVariant } from '../../types/enum/cardVariant';

type TProps = HTMLProps<HTMLDivElement> & {
  children: ReactNode;
  variant?: ECardVariant;
};

type TCardComponent = ForwardRefExoticComponent<
  Omit<TProps, 'ref'> & RefAttributes<HTMLDivElement>
> & {
  Header: FC<TProps>;
  Body: FC<TProps>;
  Footer: FC<TProps>;
};

const Card = forwardRef<HTMLDivElement, TProps>(
  (
    { children, className = '', variant = ECardVariant.DEFAULT, ...props },
    ref,
  ) => {
    const variantClassMap = {
      default: 'bg-alpha',
      info: 'bg-info/20',
      warn: 'bg-info-warning/20',
      danger: 'bg-info-error/20',
      dark: 'bg-app-700/20',
    };
    return (
      <section
        ref={ref}
        className={`${variantClassMap[variant]} print:shadow-none rounded-xl shadow-app-800/10 shadow-md ${className}`}
        {...props}>
        {children}
      </section>
    );
  },
) as TCardComponent;

const Header = ({
  children,
  className = '',
  variant = ECardVariant.DEFAULT,
  ...props
}: TProps) => {
  const variantClassMap = {
    default: 'bg-app-400',
    info: 'bg-info/40',
    warn: 'bg-info-warning/40',
    danger: 'bg-info-error/40',
    dark: 'bg-app-700 text-app-100 print:bg-app-700',
  };
  return (
    <header
      key='header'
      className={`${variantClassMap[variant]} p-4 flex justify-between rounded-t-xl items-center ${className}`}
      {...props}>
      {children}
    </header>
  );
};

const Body = ({ children, className = '', ...props }: TProps) => {
  return (
    <div className={`p-2 ${className}`} {...props} key='body'>
      {children}
    </div>
  );
};

const Footer = ({ children, className = '', ...props }: TProps) => {
  return (
    <footer
      key='footer'
      className={`p-2 bg-app-400 flex justify-between items-center ${className}`}
      {...props}>
      {children}
    </footer>
  );
};

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;

export default Card;
