import Card from '@/components/common/card';
import { fetchContent } from '@/helpers/axios/configs/contentAxios';
import { pageEl } from '@/helpers/getElements';
import { useClickOutside } from '@/hooks/useClickOutside';
import { faBell, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useState } from 'react';
import { createPortal } from 'react-dom';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { NotificationResponse } from '@signpost-hardware/management-portal-models/v1/app/content/notifications/responses/notification_response_pb';

const NotificationListItem = ({
  notification,
  unread,
  setUnread,
}: {
  notification: NotificationResponse;
  unread: string[];
  setUnread: Dispatch<SetStateAction<string[]>>;
}) => {
  const [showFullNotification, setShowNotification, fullNotificationRef] =
    useClickOutside<HTMLDivElement>({
      ignoreClass: 'ignoreFullNotification',
    });
  return (
    <>
      <li
        onClick={() => {
          setShowNotification(true);
          const newUnReads = [...new Set([...unread, notification.guid])];
          setUnread(newUnReads);
          localStorage.setItem('notificationRead', JSON.stringify(newUnReads));
        }}
        key={notification.guid}
        className={`ignoreFullNotification p-4 pr-10 cursor-pointer hover:bg-app-200 first:rounded-t-xl last:rounded-b-xl transition-colors relative ${unread.includes(notification.guid) ? 'bg-app-100 opacity-70' : 'after:content-[""] after:w-2 after:h-2 after:bg-signpost after:absolute after:rounded-full after:right-4 after:top-1/2 after:-translate-y-1/2'}`}>
        <h2 className='font-medium'>{notification.title}</h2>
        <p className='text-sm'>{notification.shortDescription}</p>
        <p className='text-sm text-app-500 underline'>Lees meer</p>
      </li>
      {showFullNotification &&
        createPortal(
          <div className='ignoreNotifications fixed top-0 left-0 w-full h-dynamic flex items-start justify-center bg-app-800/30 backdrop-blur-sm z-50'>
            <div ref={fullNotificationRef}>
              <Card className='m-4 max-w-xl'>
                <Card.Header>
                  <h2 className='font-medium text-lg'>{notification.title}</h2>
                  <button onClick={() => setShowNotification(false)}>
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </Card.Header>
                <Card.Body className='p-4 alertCard'>
                  <div>{parse(DOMPurify.sanitize(notification.content))}</div>
                </Card.Body>
              </Card>
            </div>
          </div>,
          pageEl,
        )}
    </>
  );
};

const NotificationButton = ({
  notifications,
}: {
  notifications?: NotificationResponse[];
}) => {
  const [unread, setUnread] = useState<string[]>(
    JSON.parse(localStorage.getItem('notificationRead') || '[]'),
  );
  const [showNotifications, setShowNotifications, notificationsRef] =
    useClickOutside<HTMLDivElement>({
      ignoreClass: 'ignoreNotifications',
    });

  return (
    <>
      <button
        title='notifications'
        className='relative ignoreNotifications'
        onClick={() => setShowNotifications(!showNotifications)}>
        {!notifications?.every((v) => unread.includes(v.guid)) && (
          <div className='w-2 h-2 absolute -top-1 -right-1 rounded-full bg-signpost'>
            <div className='w-full h-full rounded-full bg-[inherit] motion-safe:animate-ping' />
          </div>
        )}
        <FontAwesomeIcon icon={faBell} size='lg' />
      </button>
      <div
        ref={notificationsRef}
        className={`${
          showNotifications ? 'flex' : 'hidden'
        } headerCard absolute top-full overflow-y-auto scrollbar m-0 right-0 z-20 bg-alpha flex-col items-center gap-6 shadow-lg shadow-app-800/20 rounded-xl`}>
        <div className='flex flex-col items-center gap-2 w-full'>
          {notifications && notifications.length > 0 ? (
            <ul className='divide-y-2 divide-app-100'>
              {notifications.map((notification) => (
                <NotificationListItem
                  key={notification.guid}
                  notification={notification}
                  unread={unread}
                  setUnread={setUnread}
                />
              ))}
            </ul>
          ) : (
            <div className='p-4'>Geen meldingen</div>
          )}
        </div>
      </div>
    </>
  );
};

const Notifications = () => {
  const { data, isLoading, error } = useQuery<NotificationResponse[]>({
    queryKey: ['notifications'],
    queryFn: async () => {
      const res = await fetchContent.get('/notifications');
      return res.data.items;
    },
  });

  if (isLoading)
    return (
      <button title='notifications' className='relative'>
        <FontAwesomeIcon icon={faBell} size='lg' />
      </button>
    );

  if (error) return <></>;

  return <NotificationButton notifications={data} />;
};

export default Notifications;
