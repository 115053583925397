import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthEurope } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useClickOutside } from '../../hooks/useClickOutside';
import { RootState } from '../../redux/store';
import { setLanguage, supportedLang } from '../../redux/slices/languageSlice';
import { useEdgeDetection } from '../../hooks/useEdgeDetection';
import { useEffect } from 'react';

const LanguageSelector = () => {
  const [open, setOpen, langSelectorRef] = useClickOutside<HTMLUListElement>({
    ignoreClass: 'ignoreLang',
  });
  const [languageSelectorRef, showAbove] = useEdgeDetection<HTMLDivElement>();
  const { language, locale, lcid } = useSelector(
    (state: RootState) => state.language,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.lang = lcid;
  }, [lcid]);

  const regions: {
    nativeName: string;
    langLcid: string;
    languageName: string;
  }[] = [];
  for (const langKey in supportedLang) {
    if (Object.prototype.hasOwnProperty.call(supportedLang, langKey)) {
      const { languageName, ...supportedLocales } = supportedLang[langKey];
      for (const localeKey in supportedLocales.locales) {
        if (
          Object.prototype.hasOwnProperty.call(
            supportedLocales.locales,
            localeKey,
          )
        ) {
          const lang = supportedLocales.locales[localeKey];
          const langLcid = `${langKey}${localeKey ? `-${localeKey}` : ''}`;
          regions.push({
            nativeName: lang.nativeName,
            languageName,
            langLcid,
          });
        }
      }
    }
  }

  return (
    <div className='relative'>
      <button
        className='flex gap-1 ignoreLang items-center'
        onClick={() => setOpen(true)}>
        <FontAwesomeIcon icon={faEarthEurope} />
        <span>
          {supportedLang[language]?.locales[locale]?.nativeName ||
            supportedLang[language].languageName}
        </span>
      </button>
      <div ref={languageSelectorRef}>
        <ul
          ref={langSelectorRef}
          className={`${
            open ? 'block' : 'hidden'
          } absolute list-none w-min mb-4 bg-alpha rounded shadow p-2 ${
            showAbove ? 'bottom-full' : 'top-full'
          } `}>
          {regions.map((item) => (
            <li
              className='my-1 d-block text-decoration-none'
              key={item.langLcid}>
              <button
                onClick={() => {
                  dispatch(setLanguage(item.langLcid));
                  window.location.reload();
                }}>
                {item.nativeName}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LanguageSelector;
