import { useMutation } from '@tanstack/react-query';
import { fetchImpersonation } from '../../../helpers/axios/configs/impersonationAxios';
import { useDispatch } from 'react-redux';
import { setInstitution } from '../../../redux/slices/institutionSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';

const ImpersonationBanner = () => {
  const dispatch = useDispatch();
  const stopImpersonateMutation = useMutation({
    mutationFn: async () => {
      const res = await fetchImpersonation.delete('');
      return res.data;
    },
    onSuccess: () => {
      dispatch(setInstitution(null));
      sessionStorage.removeItem('impersonate');
      window.location.href = '/impersonate';
    },
  });
  const impersonation = sessionStorage.getItem('impersonate');
  if (!impersonation) return;
  const { account, email } = JSON.parse(impersonation);

  return (
    <div className='bg-info-error sticky bottom-0 p-4 flex justify-between items-center z-50'>
      <div className='flex flex-col'>
        <span>Impersonating: {email}</span>
        <div
          className='flex items-center gap-2 cursor-pointer hover:text-app-100 transition-colors'
          onClick={() => {
            navigator.clipboard.writeText(account);
            toast.info('Copied');
          }}>
          <span>{account}</span>
          <FontAwesomeIcon icon={faCopy} />
        </div>
      </div>
      <button
        onClick={() => stopImpersonateMutation.mutate()}
        className='bg-app-100 hover:bg-app-400 border border-app-400 rounded-md px-4 py-2'>
        stop impersonation
      </button>
    </div>
  );
};

export default ImpersonationBanner;
