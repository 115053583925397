import axios from 'axios';

const baseURL = new URL(
  '/v1/institution-contacts',
  import.meta.env.VITE_API_BASE_URL,
);

export const fetchInstitutionContacts = axios.create({
  baseURL: baseURL.toString(),
  addCurrentInstitution: true,
});
