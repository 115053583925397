import { InstitutionRoleResponse } from '@signpost-hardware/management-portal-models/v1/institution_roles/responses/institution_role_response_pb';
import { InstitutionHierarchyResponse } from '../types/institutionHierarchy';
import {
  TCheckboxState,
  TInstitutionWithLevel,
  TPermissionCheckLocator,
  TPermissionMatrix,
} from '../types/permissionTypes';
import { TPermission } from '../redux/slices/permissionSlice';
import { EPermissions } from '../types/enum/permissions';
import { AccountResponse } from '@signpost-hardware/management-portal-models/v1/institution_accounts/responses/account_response_pb';

export const flattenInstitutions = (
  institutions: InstitutionHierarchyResponse[],
  level: number = 0,
  parent: string[] = [],
): TInstitutionWithLevel[] => {
  let flattened: TInstitutionWithLevel[] = [];
  institutions.forEach((institution) => {
    const parentInstitutionGuid = institutions[0].guid || '';
    const parentInstitutionChain: string[] = [...parent];
    const { childInstitutions, ...rest } = institution;
    parentInstitutionChain.push(institution.guid || '');
    flattened.push({
      ...rest,
      level,
      parentInstitutionGuid,
      parentInstitutionChain,
    });
    if (childInstitutions.length > 0) {
      flattened = [
        ...flattened,
        ...flattenInstitutions(
          childInstitutions,
          level + 1,
          parentInstitutionChain,
        ),
      ];
    }
  });

  return flattened;
};

const findFirstMatrixElementByRole = (
  matrix: TPermissionMatrix,
  roleGuid: string,
) => {
  for (let y = 0; y < matrix.length; y++) {
    for (let x = 0; x < matrix[y].length; x++) {
      if (matrix[y][x].institutionRoleGuid === roleGuid) {
        return {
          x,
          y,
          checked: matrix[y][x].checked,
          name: matrix[y][x].name,
        };
      }
    }
  }
  return null;
};

const findMatrixElementByName = (
  matrix: TPermissionMatrix,
  targetName: string,
) => {
  for (let y = 0; y < matrix.length; y++) {
    for (let x = 0; x < matrix[y].length; x++) {
      if (matrix[y][x].name === targetName) {
        return {
          x,
          y,
          checked: matrix[y][x].checked,
          name: matrix[y][x].name,
        };
      }
    }
  }
  return null;
};

export const calculateCheckboxMatrixOncheck = (
  currentMatrix: TPermissionMatrix,
  { x, y, checked, name }: TPermissionCheckLocator,
) => {
  const newMatrix: TPermissionMatrix = JSON.parse(
    JSON.stringify(currentMatrix),
  );
  const selectedCheckbox = newMatrix[y][x];
  for (let i = 0; i < newMatrix.length; i++) {
    if (i < y) continue;
    for (let j = 0; j < newMatrix[i].length; j++) {
      if (j < x) continue;
      if (
        newMatrix[i][j].parentInstitutionChain.includes(
          selectedCheckbox.institutionGuid,
        ) &&
        (!newMatrix[y][x].checked ||
          (newMatrix[y][x].checked && !newMatrix[y][x].disabled))
      ) {
        newMatrix[i][j] = {
          ...newMatrix[i][j],
          checked: checked,
          disabled: checked && name !== newMatrix[i][j].name ? true : false,
        };
      }
    }
  }
  return newMatrix;
};

export const getSelectedPermissions = (matrix: TPermissionMatrix) => {
  const enabledPermissions: TPermissionCheckLocator[] = [];
  for (let i = 0; i < matrix.length; i++) {
    for (let j = 0; j < matrix[i].length; j++) {
      if (matrix[i][j].checked && !matrix[i][j].disabled)
        enabledPermissions.push({
          checked: matrix[i][j].checked,
          name: matrix[i][j].name,
          x: j,
          y: i,
        });
    }
  }
  return enabledPermissions;
};

export const getUpdatedPermissions = (
  currentMatrix: TPermissionMatrix,
  defaultMatrix: TPermissionMatrix,
) => {
  const changedPermissions: TCheckboxState[] = [];
  for (let i = 0; i < currentMatrix.length; i++) {
    for (let j = 0; j < currentMatrix[i].length; j++) {
      if (
        (!currentMatrix[i][j].disabled &&
          ((currentMatrix[i][j].checked !== defaultMatrix[i][j].checked &&
            !defaultMatrix[i][j].disabled) ||
            (defaultMatrix[i][j].disabled && currentMatrix[i][j].checked))) ||
        (currentMatrix[i][j].disabled &&
          currentMatrix[i][j].checked &&
          defaultMatrix[i][j].checked &&
          !defaultMatrix[i][j].disabled)
      ) {
        changedPermissions.push(currentMatrix[i][j]);
      }
    }
  }
  return changedPermissions.sort((a, b) => {
    if (!a.checked) return -1;
    if (b.checked) return +1;
    return 0;
  });
};

export const defaultMatrix = (
  institutions: TInstitutionWithLevel[],
  roles: InstitutionRoleResponse[],
  currentAccounts: AccountResponse[],
) => {
  const permissionMatrix: TPermissionMatrix = [];

  const enabledPermissions: {
    name: string;
    institutionAccountRoleGuid: string;
    accountGuid: string;
    inherited: boolean;
    roleGuid: string;
  }[] = [];
  currentAccounts.forEach((account) => {
    account.institutionAccounts.forEach((institutionAccount) => {
      institutionAccount.institutionAccountRoles.forEach((role) => {
        enabledPermissions.push({
          name: `${institutionAccount.institution?.guid}.${role.roleGuid}`,
          institutionAccountRoleGuid: `${role.guid}`,
          roleGuid: `${role.roleGuid}`,
          accountGuid: institutionAccount.guid,
          inherited: role.inherited,
        });
      });
    });
  });
  institutions.forEach((institution) => {
    permissionMatrix.push(
      roles.map((role) => ({
        name: `${institution.guid}.${role.guid}`,
        checked: false,
        disabled: false,
        level: institution.level,
        institutionGuid: institution.guid || '',
        parentInstitutionChain: institution.parentInstitutionChain,
        institutionRoleGuid: role.guid || '',
        accountGuid: '',
      })),
    );
  });

  let matrix = permissionMatrix;

  enabledPermissions.forEach((permission) => {
    let checkedPermission = findMatrixElementByName(
      permissionMatrix,
      permission.name,
    );
    if (permission.inherited) {
      checkedPermission = findFirstMatrixElementByRole(
        permissionMatrix,
        permission.roleGuid,
      );
    }

    if (checkedPermission) {
      checkedPermission.checked = true;
      matrix = calculateCheckboxMatrixOncheck(matrix, checkedPermission);
      matrix[checkedPermission.y][
        checkedPermission.x
      ].institutionAccountRoleGuid = permission.institutionAccountRoleGuid;
      matrix[checkedPermission.y][checkedPermission.x].accountGuid =
        permission.accountGuid;
      if (permission.inherited)
        matrix[checkedPermission.y][checkedPermission.x].disabled = true;
    }
  });

  return matrix;
};

export const hasCorrectPermissions = (
  userPermissions: TPermission[],
  neededPermissions: EPermissions | EPermissions[],
) => {
  const requiredPermissionsArray = Array.isArray(neededPermissions)
    ? neededPermissions
    : [neededPermissions];
  return requiredPermissionsArray.every((permission) =>
    userPermissions.includes(permission),
  );
};
